<template>
  <div class="unifiedMessagingService">

    <el-radio-group @change="tabChange" style="margin-bottom:20px;" v-model="radio1">
        <el-radio-button :label="1">教师</el-radio-button>
        <el-radio-button :label="2">家长</el-radio-button>
    
    </el-radio-group>

    <div>
      <el-input placeholder="请输入意见"
                clearable
                @clear="loadFeedback(1)"
                v-model="query.opinion"
                class="input-box"></el-input>
      <el-button type="primary"
                 @click="loadFeedback(1)">查 询</el-button>
    </div>
    <el-table :data="feedbackList">
      <el-table-column label="提交人"
                       align="center"
                       prop="opername">
      </el-table-column>
      
      <el-table-column label="联系电话"
                       width="120px"
                       align="center"
                       prop="mobile"></el-table-column>
      <el-table-column label="提交时间"
                       align="center"
                       prop="opertime"></el-table-column>
      <el-table-column label="反馈意见"
                       align="center"
                       prop="opinion"></el-table-column>
        <el-table-column label="操作"
            align="center"
            width="200px">
            <template slot-scope="scope">
            <el-button type="primary"
                        @click="detail(scope.row)">详情</el-button>
            
            </template>
        </el-table-column>

    </el-table>
    <el-pagination background
                   layout="prev,pager,next"
                   :page-count="page.pageCount"
                   :page-size="page.currentPage"
                   @current-change="loadFeedback"></el-pagination>

    <el-dialog
      title="查看"
      :visible.sync="feedbackDialogVisible"
      width="600px"
    >
      <div class="center">
        <el-form
          :model="feedbackForm"
          ref="feedbackFormRef"
          label-width="100px"
          
        >
          <el-form-item label="意见与反馈：" prop="opinion">
            <el-input
              type="textarea"
              disabled
              v-model="feedbackForm.opinion"
              placeholder="请输入意见反馈"
            ></el-input>
          </el-form-item>
          <el-form-item label="图片：" prop="url">
            <el-image style="width:160px;" :src="feedbackForm.url"></el-image>
            <!-- <el-input
              v-model="feedbackForm.url"
              placeholder="请输入地址"
            ></el-input> -->
          </el-form-item>

        </el-form>
      </div>
     
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="feedbackDialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="feedbackConfirm">确 定</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "unifiedMessagingService",
  data() {
    return {
      

      page: { currentPage: 0, pageCount: 1 },
      radio1:1,
      query:{
          opinion:''
      },
      feedbackList:[],
      feedbackForm:{
        opinion:'',
        url:''
      },
      feedbackDialogVisible:false,
      query:{
        opinion:''
      }
    };
  },
  created() {
    // this.loadMessage(1);
    this.loadFeedback(1)
  },
  methods: {
    // 时间转换
    getTime(time) {
      let d = new Date(time);
      let year = d.getFullYear();
      let month = d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      let date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      let hour = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
      let minutes = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
      return `${year}-${month}-${date} ${hour}:${minutes}`;
    },
    loadFeedback(page){
        this.$post("/base/queryFeedback.do", {
            indexNo:page,
            type:this.radio1,
            opinion:this.query.opinion
        })
        .then((res) => {
            console.log(res)
            
            this.page.currentPage = res.data.indexNO;
            this.page.pageCount = res.data.pageCount;
            res.data.rows.forEach(item=>{
              item.opertime = this.getTime(item.opertime)

              item.photoUrl = this.$fileUploadDomain + item.url;
              item.photoUrlList = [];
              item.photoUrlList.push(this.$fileUploadDomain + item.url);
            })
            this.feedbackList = res.data.rows;
        })
        .catch((err) => {
            this.$message({
                type: "warning",
                message: err.message,
            });
        });
    },
    detail(row){
      this.feedbackDialogVisible = true
      this.$nextTick(()=>{  
        this.feedbackForm.opinion = row.opinion
        this.feedbackForm.url = row.photoUrl
        console.log(this.feedbackForm.url)
        // this.feedbackForm.url = row.url
      })
    },
    tabChange(value){
      console.log(value)
      this.loadFeedback(1)
    },
    
  },
};
</script>

<style scoped lang="scss">
.input-box {
  width: 200px;
  margin-right: 5px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
